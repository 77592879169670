export default {
  messages: {
    uk: {
      'Залишились запитання?': 'Залишились запитання?',
      'Звертайтесь до менеджера центру перевірок': 'Звертайтесь до менеджера центру перевірок',
      'Замовити зворотній дзвінок': 'Замовити зворотній дзвінок',
      'Ваше имя': 'Ваше ім`я',
      Tелефон: 'Tелефон',
      'Зручний час': 'Зручний час',
      'Передзвоніть мені': 'Передзвоніть мені',
      'Ваша заявка на зворотній дзвінок прийнята': 'Ваша заявка на зворотній дзвінок прийнята',
      'Найближчим часом менеджер зв\'яжеться з вами.': 'Найближчим часом менеджер зв\'яжеться з вами.',
      'Працюємо щодня з': 'Працюємо щодня з {from} до {to}',
      'Ваші персональні дані будуть оброблені та захищені': 'Ваші персональні дані будуть оброблені та захищені відповідно до',
      'Політики приватності': 'Політики приватності',
      'Сталася помилка': 'Сталася помилка',
      'Ваше імя': 'Ваше ім\'я',
    },
    ru: {
      'Залишились запитання?': 'Остались вопросы?',
      'Звертайтесь до менеджера центру перевірок': 'Свяжитесь с менеджером центра проверок',
      'Замовити зворотній дзвінок': 'Заказать обратный звонок',
      'Ваше имя': 'Ваше имя',
      Tелефон: 'Tелефон',
      'Зручний час': 'Удобное время',
      'Передзвоніть мені': 'Перезвоните мне',
      'Ваша заявка на зворотній дзвінок прийнята': 'Ваша заявка на обратный звонок принята',
      'Найближчим часом менеджер зв\'яжеться з вами.': 'В ближайшее время менеджер свяжется с вами.',
      'Працюємо щодня з': 'Работаем каждый день с {from} до {to}',
      'Ваші персональні дані будуть оброблені та захищені': 'Ваши персональные данные будут обработаны и защищены в соответствии с',
      'Політики приватності': 'Политики конфиденциальности',
      'Сталася помилка': 'Произошла ошибка',
      'Ваше імя': 'Ваше имя',
    },
  },
};
