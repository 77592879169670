import { mapGetters } from 'vuex';
import i18n from './i18n';
import { get } from '../../../helpers/cookie';
import { autotestAddQestion as addQestion } from '../../../store/queries';
import getCallbackId from '../../../helpers/getCallbackId';

export default {
  name: 'ForCommunications',
  i18n,
  data() {
    return {
      from: '09:00',
      to: '18:00',
      contactPhone: '+380930429264',
      viberLink: 'viber://chat?number=%2B380674313453',
      telegramLink: 'tg://resolve?domain=RiaCentre',
      phone: '',
      questionSource: '',
      inputUserName: '',
      convenientTime: '',
      isSubmit: false,
      showError: false,
      isTest: false,
    };
  },
  created() {
    this.questionSource = this.$route.path;
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      userData: 'Common/userData',
    }),
  },
  methods: {
    async addQestion() {
      const {
        inputUserName,
        phone,
        questionSource,
        convenientTime,
        userData: { userId } = {},
      } = this;

      if (phone) {
        try {
          const data = await addQestion({
            phone,
            phone_time: convenientTime,
            userName: inputUserName,
            userId,
            questionSource,
            pageIdentifier: getCallbackId(this.$route.name),
          });

          if (data.status === 'ok') {
            this.showError = false;
            this.isSubmit = true;
          }
        } catch (err) {
          this.showError = true;
        }
      }
    },
    onInput(event) {
      const value = event.target.value.replace(/\D+/g, '');
      const numberLength = 12;
      let result = '';

      for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
          case 0:
            result += '+38 (0';

            if (value.length === 1) result += value[i];
            continue;
          case 1:
          case 2:
            continue;
          case 5:
            result += ') ';
            break;
          case 8:
          case 10:
            result += ' ';
            break;
          default:
            break;
        }

        result += value[i];
      }

      this.phone = result;
    },
  },

  mounted() {
    if (get('test_VCWEB-5943') === '1') {
      this.isTest = true;
    }
  },

  watch: {
    userData: {
      handler() {
        if (!this.phone && this.userData?.userPhones?.[0]) {
          this.phone = this.userData?.userPhones?.[0];
        }
        if (!this.inputUserName && this.userData?.userName) {
          this.inputUserName = this.userData?.userName;
        }
      },
      deep: true,
    },
  },
};
